<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Y.T.I.P Degree</h5>
      </div>
    </div>
    <!--Banner Wrap End-->

    <div class="iq_content_wrap">
      <section class="about-us-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="text">
                <!--Heading Wrap Start-->
                <div class="iq_heading_1 text-left">
                  <h4>YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY <span>(DEGREE)</span></h4>
                </div>
                <p>DTE CODE-PH3232</p>
                <p>Recognized by P. C.I Approved by AICTE / DTE-PH 3232, <br>
                  Affiliation to University of Mumbai</p>
                <!--Heading Wrap End-->
                <p>
                  Saraswati Education society (SES) is established in the year 2003. The chairman of trust Dr. N.Y Tasgaonkar himself is renowned “Doctor, under his dynamic leadership all Institutes a reprocessing by keeping pace with the world. Today SES is known as leading Educational group in Maharashtra in a very short span of 07years establishing Institutes such as colleges Engineering, Managements, Pharmacy (Degree & Diploma) and polytechnic colleges. Campus amidst the foots of Matheran near Bhivpuri road station surrounded by Sahyadri mountains and right on the banks of Ulhas river with state of art modern facilities.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="text-center">
                <img height="300px" src="images/logo.png" alt="about_us">
              </div>
            </div>
          </div>
        </div>
      </section>

      <PrincipalMessage :principe-img="principleImg">
        <div class="iq_knowledge_des">
          <!--Heading Wrap Start-->
          <div class="iq_heading_1 text-left">
            <h4>FROM THE DESK OF <span>THE PRINCIPAL</span></h4>
          </div>
          <!--Heading Wrap End-->
          <p>Pharmaceutical industry is rated as one of the gigantic growing sectors emerging strongly with various
            avenues available for the fresh undergraduates to pursue a career in .Increased health care needs and
            medication to treat and prevent diseases with growing population has created a worldwide shortage of
            pharmacists.</p>
          <p>Additionally new opportunities are emerging for pharmacist in community pharmacies, hospitals, long-term
            care facilities, pharma industry, managed-care organizations, government agencies and in research and
            disease management. Pharmacist although remain out of the limelight, the profession is consistently ranked
            as one of the most trusted and respected one in the community because of the important care and health
            services they provide.</p>
          <p>We at YTIP aim to impart integrated and comprehensive education in pharmacy and mould your career into
            a pharmacist in the true sense. With the best of infrastructure, well equipped laboratories with instruments
            and machineries based on latest technologies, rich library with variety of books provides a conducive
            environment for initiating research activities. Along with academics we also make earnest efforts for the
            students to be familiar s with the pharma world by providing extensive interaction with the industry by
            arranging industry visits, guest lectures and seminars by industry personnel, and research activities at the
            undergraduate level .The dedicated and well trained staff which is a back-bone of our institute will not only
            give you intensive and expansive coaching in academics but will strengthen your understanding of
            professional ethics and practice management responsibilities, making you the best health care personnel.</p>
          <p>So come and join us at YTIP and assure yourself for selecting the most respected and financially rewarding
            career providing relatively high security even in times of economic downturns.</p>
          <h5 class="mb-0">Dr. Rupali Tasgaonkar</h5>
          <p>Principal, YTIP</p>
        </div>
      </PrincipalMessage>

      <section class="about-us-section">
        <div class="container">
          <div class="text departments-row">
            <!--Heading Wrap Start-->
            <div class="iq_heading_1 text-left">
              <h4>Departments</h4>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="department-box">
                  <h6>PHARMACEUTICS</h6>
                  <p>
                    Pharmaceutics is the discipline of pharmacy that deals with the process of turning a new chemical entity (NCE) or old drugs into a medication to be used safely and effectively by patients. It is also called the science of dosage form design. It involves the design, development, and evaluation of drug formulations in an appropriate dosage form. The students learn formulation and manufacture of solid dosage forms like tablets, capsules, Liquid dosage forms like solutions, suspensions, emulsions & parenteral, and semisolid dosage forms like creams, gels, lotions etc.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="department-box">
                  <h6>PHARMACOGNOSY</h6>
                  <p>
                    Pharmacognosy is the study of the physical, chemical, biochemical, and biological properties of drugs, drug substances, or potential drugs or drug substances of natural origin as well as the search for new drugs from natural sources. In this subject of pharmacognosy, students study the origin, distribution, biological sources, collection, cultivation, evaluation, and pharmacological activities of crude drugs as well as their application of it in herbal medicine and cosmetics.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="department-box">
                  <h6>PHARMACOLOGY</h6>
                  <p>
                    Pharmacology is the study of sources, uses, and mechanisms of action of drugs. In Pharmacology the students learn how the drugs act on biological systems and how the body responds to the drug. Practically the students measure the Dose Response Curve of various drugs on isolated tissue preparation.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="department-box">
                  <h6>PHARMACEUTICAL CHEMISTRY</h6>
                  <p>
                    Pharmaceutical /medicinal chemistry deals with the drug design and synthesis of biologically active molecules. It includes optimization of a known drug molecule or discovery and synthesis of a new drug molecule. The students learn to synthesize number of drugs and analyze various structures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-bg">
        <div class="container">
          <div class="mandatory-disclosures">
            <div class="iq_heading_1 text-left">
              <h4>Mandatory disclosures</h4>
            </div>
            <p>
              Mandatory disclosures as per AICTE NORMS 2021-22 <a class="text-success" href="/docs/2021-22 Mandatory disclosures as per AICTE NORMS 2021-22.pdf" target="_blank"><b>View</b></a>
            </p>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <!--about_courses START-->
          <div class="about_courses">
            <!--about_courses_thumb START-->
            <div class="about_courses_thumb">

              <!--Tab Menu Wrap Start-->
              <div class="iq_tab_menu">
                <ul id="tabs" data-tabs="tabs">
                  <li class="active"><a data-toggle="tab" href="#tab1">ANTI RAGGING</a></li>
                  <li><a data-toggle="tab" href="#tab2">Institute Industry</a></li>
                  <li><a data-toggle="tab" href="#tab3">GRIEVANCE REDRESSAL</a></li>
                  <li><a data-toggle="tab" href="#tab4">COLLEGE DEVELOPMENT</a></li>
                  <li><a data-toggle="tab" href="#tab5">GOVERNING BODY</a></li>
                  <li><a data-toggle="tab" href="#tab6">INTERNAL COMPLAINT</a></li>
                  <li><a data-toggle="tab" href="#tab7">SC/ST GRIEVANCE</a></li>
                  <li><a data-toggle="tab" href="#tab8">WOMEN GRIEVANCE REDRESSAL</a></li>
                </ul>
              </div>

              <!--Tab Menu Wrap End-->
              <div id="my-tab-content" class="tab-content">
                <div class="tab-pane active" id="tab1">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>ANTI RAGGING <span>COMMITTEE</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td colspan="2">
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>01</p>
                          </td>
                          <td>
                            <p>Head of the college</p>
                          </td>
                          <td>
                            <p>Chairperson</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal, YTIP -Degree</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>02</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member Secretary</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Head- Exam</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>03</p>
                          </td>
                          <td>
                            <p>Representative of Police</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. S.P. Narute</p>
                          </td>
                          <td>
                            <p>PO-Karjat Police Station</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>04</p>
                          </td>
                          <td>
                            <p>Local Media</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Dharmanand Gaikwad</p>
                          </td>
                          <td>
                            <p>Journalist-Raigad Times</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>05</p>
                          </td>
                          <td>
                            <p>Non Govt. Org.</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Pankaj Mhase</p>
                          </td>
                          <td>
                            <p>Member-Lion&rsquo;s Club Neral</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>06</p>
                          </td>
                          <td>
                            <p>Representative of Parents</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mrs. Deepali Tambe</p>
                          </td>
                          <td>
                            <p>Parent</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;07</p>
                          </td>
                          <td>
                            <p>Representative of Non-Teaching Staff</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Maya Mhaske</p>
                          </td>
                          <td>
                            <p>Clerk</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;08</p>
                          </td>
                          <td>
                            <p>Representative of Civil Administration</p>
                          </td>
                          <td>
                            <p>Mamlatdar</p>
                          </td>
                          <td>
                            <p>Mr. Samadhan Sawarkar</p>
                          </td>
                          <td>
                            <p>Field Officer- Kadav</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;09</p>
                          </td>
                          <td>
                            <p>Representative from fresher student</p>
                          </td>
                          <td>
                            <p>Student</p>
                          </td>
                          <td>
                            <p>Mr. Ganesh Bhandare</p>
                          </td>
                          <td>
                            <p>Direct Second &nbsp;Year student</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;10</p>
                          </td>
                          <td>
                            <p>Representative from fresher student</p>
                          </td>
                          <td>
                            <p>Student</p>
                          </td>
                          <td>
                            <p>Ms. Prajkta Kamble</p>
                          </td>
                          <td>
                            <p>Direct Second &nbsp;Year student</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;11</p>
                          </td>
                          <td>
                            <p>Representative from Senior student</p>
                          </td>
                          <td>
                            <p>Student</p>
                          </td>
                          <td>
                            <p>Ms. Ayushi Jain</p>
                          </td>
                          <td>
                            <p>Final Year</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;12</p>
                          </td>
                          <td>
                            <p>Representative from Senior student</p>
                          </td>
                          <td>
                            <p>Student&nbsp;</p>
                          </td>
                          <td>
                            <p>Mr. Sanket Wagh</p>
                          </td>
                          <td>
                            <p>Final Year</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;13</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>14</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Amol Kharche</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>15</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Puja Vyawahare</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>&nbsp;16</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Akshay Ghuge</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <br>

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>ANTI RAGGING <span>SQUAD</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td colspan="2">
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Phone No.</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>01</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member&nbsp;</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>7738648208</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>02</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Amol Kharche</p>
                          </td>
                          <td>
                            <p>7875344878</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>03</p>
                          </td>
                          <td>
                            <p>Hostel Representative</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Sharayu Tasgaonkar</p>
                          </td>
                          <td>
                            <p>8657260542</p>
                          </td>
                          <td>
                            <p>Rector &ndash; Girls Hostel</p><br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>04</p>
                          </td>
                          <td>
                            <p>Representative of Non-Teaching Staff</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Maya Mhaske</p>
                          </td>
                          <td>
                            <p>8108420766</p>
                          </td>
                          <td>
                            <p>Clerk</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>05</p>
                          </td>
                          <td>
                            <p>Hostel Reprentative</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr, Uttam Sonavane</p>
                          </td>
                          <td>
                            <p>9594970318</p>
                          </td>
                          <td>
                            <p>Rector &ndash; Boys &nbsp;Hostel</p><br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>06</p>
                          </td>
                          <td>
                            <p>Representative of Student- Fresher Category</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Ganesh Bhandare</p>
                          </td>
                          <td>
                            <p>7066617653</p>
                          </td>
                          <td>
                            <p>Student First Year</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>07</p>
                          </td>
                          <td>
                            <p>Representative of Senior Student</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Ayushi Jain</p>
                          </td>
                          <td>
                            <p>8108691421</p>
                          </td>
                          <td>
                            <p>Student Final &nbsp;Year</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab2">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>Institute Industry <span>Cell</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>01</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p><br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>02</p>
                          </td>
                          <td>
                            <p>Member&nbsp;</p>
                          </td>
                          <td>
                            <p>Mrs Shital Kalekar</p>
                          </td>
                          <td>
                            <p>Teaching Staff Final Year Class Incharge</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>03</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr Prachitee Ayare</p>
                          </td>
                          <td>
                            <p>Teaching Staff Third Year Class Incharge</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>04</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mrs Karthika Nair</p>
                          </td>
                          <td>
                            <p>Teaching Staff Second Year Class Incharge</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>05</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Rajeshwari Patil</p><br>
                          </td>
                          <td>
                            <p>Teaching Staff</p><br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>06</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Amol Kharche</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab3">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>GRIEVANCE <span>COMMITTEE</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>1</p>
                          </td>
                          <td>
                            <p>Management Council Member</p>
                          </td>
                          <td>
                            <p>Mr. Rajesh Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Trustee&nbsp;</p>
                            <p>Co-ordinator of SES</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>2</p>
                          </td>
                          <td>
                            <p>Women Representative From Management</p>
                          </td>
                          <td>
                            <p>Mrs. Vandana Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Joint Secretary of SES</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>3</p>
                          </td>
                          <td>
                            <p>Academic Council Member</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal, Degree YTIP</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>4</p>
                          </td>
                          <td>
                            <p>Representative From Staff</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5</p>
                          </td>
                          <td>
                            <p>Representative From Staff</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p><br>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab4">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>COLLEGE DEVELOPMENT <span>COMMITTEE</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Name of the member</p>
                          </td>
                          <td>
                            <p>Designation</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>1</p>
                          </td>
                          <td>
                            <p>Dr. N. Y. Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Chairman of</p>
                            <p>&nbsp;the society</p>
                          </td>
                          <td>
                            <p>Chairman</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>2</p>
                          </td>
                          <td>
                            <p>Mrs.Vandana Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Joint Secretary&nbsp;</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>3</p>
                          </td>
                          <td>
                            <p>Dr. Pandharinath Ghonge</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Expert from education field</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>4</p>
                          </td>
                          <td>
                            <p>Mr. Rajesh Kulkarni</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Expert from Industry</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5</p>
                          </td>
                          <td>
                            <p>Dr. Bapu Gawade</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Expert from Research field</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>6</p>
                          </td>
                          <td>
                            <p>Mr. Pankaj Mhase</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Expert from Social Service</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>7</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Teaching staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>8</p>
                          </td>
                          <td>
                            <p>Ms.Puja Vyawahare</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Teaching staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>9</p>
                          </td>
                          <td>
                            <p>Ms.Shital Kalekar</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Teaching staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>10</p>
                          </td>
                          <td>
                            <p>Ms. Sangeeta Fadnis</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Non &ndash;Teaching staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>11</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Coordinator IQAC</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>12</p>
                          </td>
                          <td>
                            <p>Mr. Vaibhav Suryavanshi</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>GS- Student council</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>13</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal</p>
                          </td>
                          <td>
                            <p>Member Secretary</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab5">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>GOVERNING <span>BODY</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Name of the member</p>
                          </td>
                          <td>
                            <p>Qualification</p>
                          </td>
                          <td>
                            <p>Responsibility</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>1</p>
                          </td>
                          <td>
                            <p>Dr. N. Y. Tasgaonkar</p>
                          </td>
                          <td>
                            <p>M.B.B.S; MD. (Radiology), DMRD, DMRE</p>
                          </td>
                          <td>
                            <p>Chairman and Managing Director, to arrange finance and marketing of Institute</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>2</p>
                          </td>
                          <td>
                            <p>Mr. Rajesh Tasgaonkar</p>
                          </td>
                          <td>
                            <p>LLB</p>
                          </td>
                          <td>
                            <p>Trustee Co-ordinator</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>3</p>
                          </td>
                          <td>
                            <p>Dr. S. R. Gangavane</p>
                          </td>
                          <td>
                            <p>MBBS</p>
                          </td>
                          <td>
                            <p>To give logistic support to all activities of Dr. N. Y. Tasgaonkar</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>4</p>
                          </td>
                          <td>
                            <p>Mr. Y. H. Kamble</p>
                          </td>
                          <td>
                            <p>BA (Hon.), Retired Deputy Secretary</p>
                          </td>
                          <td>
                            <p>Day to day administration of the institute</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5</p>
                          </td>
                          <td>
                            <p>Mr.Nitin Kandalgaonkar</p>
                          </td>
                          <td>
                            <p>Industrialist</p>
                          </td>
                          <td>
                            <p>To provide information about growth of industry and demand of industries</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>6</p>
                          </td>
                          <td>
                            <p>Mr. Ram Kolambe</p>
                          </td>
                          <td>
                            <p>Social Worker</p>
                          </td>
                          <td><br></td>
                        </tr>
                        <tr>
                          <td>
                            <p>7</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>M. Pharm, PhD</p>
                          </td>
                          <td>
                            <p>Principal</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab6">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>INTERNAL COMPLAINT <span>COMMITTEE</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>1</p>
                          </td>
                          <td>
                            <p>Ex-officio president of the cell</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal</p>
                            <p>&nbsp;YTIP-Degree</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>2</p>
                          </td>
                          <td>
                            <p>Women representative from management</p>
                          </td>
                          <td>
                            <p>Mrs Vandana Tasgaonkar.</p>
                          </td>
                          <td>
                            <p>Joint secretary of SES</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>3</p>
                          </td>
                          <td>
                            <p>Convener</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>4</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5</p>
                          </td>
                          <td>
                            <p>NGO Member&nbsp;</p>
                          </td>
                          <td>
                            <p>Dr. Reena Gaikwad</p>
                          </td>
                          <td>
                            <p>Doctor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>6</p>
                          </td>
                          <td>
                            <p>Student Representative</p>
                          </td>
                          <td>
                            <p>Ms. Purva Joshi</p>
                          </td>
                          <td>
                            <p>Final Year Student&nbsp;</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>7</p>
                          </td>
                          <td>
                            <p>Advocate</p>
                          </td>
                          <td>
                            <p>Mr. M.P.Sadekar</p>
                          </td>
                          <td>
                            <p>Law Expert</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab7">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>SC/ST GRIEVANCE <span>CELL</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td colspan="2">
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>01</p>
                          </td>
                          <td>
                            <p>Head of the college</p>
                          </td>
                          <td>
                            <p>Chairperson</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal, YTIP -Degree</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>02</p>
                          </td>
                          <td>
                            <p>Representative of Faculty Member</p>
                          </td>
                          <td>
                            <p>Member Secretary</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>03</p>
                          </td>
                          <td>
                            <p>Representative of Parents</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mrs.Deepali Tambe</p>
                          </td>
                          <td>
                            <p>Parent</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>04</p>
                          </td>
                          <td>
                            <p>Representative of Non-Teaching Staff</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Maya Mhaske</p>
                          </td>
                          <td>
                            <p>Clerk</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>05</p>
                          </td>
                          <td>
                            <p>Representative from &nbsp;student</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Ms. Kajal Tambe</p>
                          </td>
                          <td>
                            <p>Final &nbsp;Year Student</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>06</p>
                          </td>
                          <td>
                            <p>Representative from &nbsp;student</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Mr. Ajay Atme</p>
                          </td>
                          <td>
                            <p>Final Year Student</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div class="tab-pane" id="tab8">
                  <div class="about_courses_thumb_capstion">

                    <div class="iq_heading_1 iq_heading_2 text-left">
                      <h4>WOMEN GRIEVANCE <span>REDRESSAL</span></h4>
                    </div>

                    <div>
                      <table>
                        <tbody>
                        <tr>
                          <td>
                            <p>Sr. No.</p>
                          </td>
                          <td>
                            <p>Structure of Committee</p>
                          </td>
                          <td>
                            <p>Name</p>
                          </td>
                          <td>
                            <p>Status</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>01</p>
                          </td>
                          <td>
                            <p>Ex-officio president of the cell</p>
                          </td>
                          <td>
                            <p>Dr. Rupali Tasgaonkar</p>
                          </td>
                          <td>
                            <p>Principal</p>
                            <p>&nbsp;YTIP-Degree</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>02</p>
                          </td>
                          <td>
                            <p>Women representative from management</p>
                          </td>
                          <td>
                            <p>Mrs Vandana Tasgaonkar.</p>
                          </td>
                          <td>
                            <p>Joint secretary of SES</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>03</p>
                          </td>
                          <td>
                            <p>Convener</p>
                          </td>
                          <td>
                            <p>Dr. Madhura Rege</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>04</p>
                          </td>
                          <td>
                            <p>Member</p>
                          </td>
                          <td>
                            <p>Dr. Babita More</p>
                          </td>
                          <td>
                            <p>Teaching Staff</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>05</p>
                          </td>
                          <td>
                            <p>NGO Member&nbsp;</p>
                          </td>
                          <td>
                            <p>Dr. Reena Gaikwad</p>
                          </td>
                          <td>
                            <p>Doctor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>06</p>
                          </td>
                          <td>
                            <p>Student Representative</p>
                          </td>
                          <td>
                            <p>Ms. Purva Joshi</p>
                          </td>
                          <td>
                            <p>Final Year Student&nbsp;</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--about_courses end-->
        </div>
      </section>

    </div>

  </div>
</template>

<script>
import PrincipalMessage from '../components/PrincipalMessage'
import principleImg from '../assets/images/degree-principle.jpeg'

export default {
  data: function () {
    return {
      principleImg: principleImg,
      committee: [
        {
          name: ''
        }
      ]
    }
  },
  components: {
    PrincipalMessage
  }
}
</script>

<style lang="scss">
.section-bg {
  background-color: #f0f0f0;
}
.departments-row {
  .iq_heading_1 {
    float: unset;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  .department-box {
    padding: 15px;
    border: 1px solid #62a13a;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px !important;
    h6 {
      margin-bottom: 10px !important;
    }
    p {
      float: none;
    }
  }
  .text-green {
    color: #62a13a!important;
  }
}
</style>
